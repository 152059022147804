import constantes from "@/constantes/constantes";
import { IErrorRessource } from "@/types/IErrorCustom";
import { IUser, ILoginInfos, IUserChangePassword } from "@/types/IUser.interface";
import toolsService from "./tools.service";


export default {
    async userLogin(user: IUser): Promise<ILoginInfos> {
        const requestOptions = toolsService.getRequestOption("POST", JSON.stringify({username: user.email, password: user.password}));
        return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/auth/login", requestOptions, user, "user.service.ts", "userLogin"); 
    },
    async getMe(): Promise<IUser> {
        const requestOptions = toolsService.getRequestOption("POST", "");
        return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/auth/me", requestOptions, {}, "user.service.ts", "getMe"); 
    },
    async getUsers(): Promise<IUser[]> {
        if (toolsService.isUserConnected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/user", requestOptions, {}, "user.service.ts", "getUsers"); 
        } else {
            return new Promise(resolve => resolve([] as IUser[]));
        }
    },
    async createUser(user: IUser): Promise<IUser> {
        const _user = {
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            archived: user.archived,
            phoneNumber: String(user.phoneNumber),
            phoneCode: user.phoneCode,
            username: user.username,
            password: user.password,
            typeUser: user.typeUser,
            birthdate: user.birthdate,
            userToShop: user.userToShop
        } as IUser;
        const requestOptions = toolsService.getRequestOption("POST", JSON.stringify(_user));
        try {
            const userCreated = await toolsService.fetchRessource(toolsService.getApiUrl() + "v1/user", requestOptions, _user, "user.service.ts", "createUser");
            (userCreated as IUser).userToShop = user.userToShop;
            (userCreated as IUser).phoneCode = user.phoneCode;
            (userCreated as IUser).phoneNumber = user.phoneNumber;
            // update user for shop 
            // if (user.userToShop && user.userToShop.length > 0) {
            //     await this.updateUser(userCreated);
            // } 
            return userCreated;
            
        } catch (error) {
            throw (error as Error);
        } 
    },
    async updateUser(user: IUser): Promise<void> {
        user.phoneNumber = String(user.phoneNumber);
        const requestOptions = toolsService.getRequestOption("PUT", JSON.stringify(user));
        await toolsService.fetchRessource(toolsService.getApiUrl() + "v1/user/" + user.id, requestOptions, user, "user.service.ts", "updateUser");
    },
    async changePassword(payload: IUserChangePassword): Promise<IUser | IErrorRessource> {
        const requestOptions = toolsService.getRequestOption("PUT", JSON.stringify(payload));
        return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/user/password/" + payload.userId, requestOptions, payload, "user.service.ts", "changePassword"); 
    },
    async resetPassword(payload: number): Promise<boolean> {
        const requestOptions = toolsService.getRequestOption("POST", "");
        try {
            await toolsService.fetchRessourceWithoutAnswer(toolsService.getApiUrl() + "v1/user/password/" + payload, requestOptions, payload, "user.service.ts", "resetPassword");
            return true;    
        } catch (error) {
            return false;
        }
    }
}